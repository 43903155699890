import React from 'react';
import { FormattedHTMLMessage } from "gatsby-plugin-intl";

import { computeWaveShapeHeight } from '../../utils/helpers';

const Check = ({ technology }) => {
    return (
        <div className="mb-3 mr-4 ml-lg-0 mr-lg-4">
            <div className="d-flex align-items-center">
                <div className="badge-primary-soft rounded p-1">
                    <svg width={20} height={20} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="feather feather-check">
                        <polyline points="20 6 9 17 4 12" />
                    </svg>
                </div>
                <p className="mb-0 ml-3">{technology}</p>
            </div>
        </div>
    )
}

const LogoContainer = ({ icons, special, showFirstOnMobile, rotate }) => {
    if (special) {
        const allTracks = icons.map((iconList, i) => {
            if (iconList.length === 1) {
                const Icon = iconList[0]
                return (
                    <div key={`${i}-parent`} className="row align-items-center justify-content-center">
                        <div className={`col-3 text-center ${i == 2 ? "App-logo": ""}`}><Icon /></div>
                    </div>
                )
            } else {
                return (
                    <div key={`${i}-parent`} className={`row align-items-center justify-content-around ${i == 2 ? "py-4" : ""}`}>
                        {iconList.map((Icon, index) => (
                        <div key={`${index}-child`} className={`col-3 text-center ${(i == 2 && index == 1) ? "App-logo": ""}`}>
                            <Icon
                                special={i == 3 && index == 1}
                            />
                        </div>))}
                    </div>
                    )
            }
        })
        return (
            <div className={`col-11 col-sm-10 col-md-7 col-lg-4 mb-0 ${showFirstOnMobile ? "order-first order-lg-0" : ""}`}>
                {allTracks}
            </div>
        )
    }
    return (
        <div className={`col-11 col-sm-10 col-md-7 col-lg-4 mb-0 ${showFirstOnMobile ? "order-first order-lg-0" : ""}`}>
            <div className="row align-items-center justify-content-around">
                {icons.map((Icon, i) => (<div key={i} className={`col-5 text-center ${rotate ? "App-logo" : ""}`}><Icon /></div>))}
            </div>
            {/* <img src={require(`../../assets/images/about/03.png`)} alt="Image" className="img-fluid" /> */}
        </div>
    )
}

const TextContainer = ({ title, description, technologies }) => {
    return (
        <div className="col-12 col-lg-8 col-xl-7">
            <div>
                {/* <span className="badge badge-primary-soft p-2">
                    <i className="la la-exclamation ic-3x rotation" />
                </span> */}
                <h2 className="mt-1 font-w-6">{title}</h2>
                <p className="lead text-justify">
                    <FormattedHTMLMessage
                        id={description}
                    />
                </p>
            </div>
            <div className="d-flex flex-wrap justify-content-start">
                {
                    technologies.map((technology, i) => <Check key={i} technology={technology} />)
                }
            </div>
            {/* <Link to="/" className="btn btn-outline-primary mt-5">
                Learn More
            </Link> */}
        </div>
    )
}

const About = ({ logoPosition = "left", title, description, icons, special, technologies, width, height, fill = "white", bg, rotate, last }) => {
    return (
        <section className={`pb-1 pt-5 mb-lg-0 ${width < 992 ? "position-relative custom-p-t" : ""} ${last ? "pb-3 pb-md-5 pb-lg-9" : ""}`} style={{ background: (bg && width < 992) ? "#eee" : "" }}>
            <div className="container">
                <div className="row align-items-center justify-content-center justify-content-lg-between">
                    {
                        logoPosition === "left"
                            ? (
                                <>
                                    <LogoContainer icons={icons} special={special} rotate={rotate} />
                                    <TextContainer title={title} description={description} technologies={technologies} />
                                </>
                            )
                            : logoPosition === "right" && (
                                <>
                                    <TextContainer title={title} description={description} technologies={technologies} />
                                    <LogoContainer icons={icons} special={special} showFirstOnMobile={true} rotate={rotate} />
                                </>
                            )
                    }

                </div>
            </div>
            {
                width < 992 && (
                    <div className="shape-1" style={{ height: computeWaveShapeHeight(width), overflow: 'hidden' }}>
                        <svg viewBox="0 0 500 150" preserveAspectRatio="none" style={{ height: '100%', width: '100%' }}>
                            <path d="M0.00,49.98 C150.00,150.00 271.49,-50.00 500.00,49.98 L500.00,0.00 L0.00,0.00 Z" style={{ stroke: 'none', fill: fill == 'gray' ? '#eee' : '#fff' }} />
                        </svg>
                    </div>
                )
            }
        </section>
    );
}

export default About;