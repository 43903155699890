import React, { Component } from 'react';
import { Link } from 'gatsby';
import { Col, Container, Row } from 'reactstrap';
import { FormattedMessage, FormattedHTMLMessage, useIntl } from "gatsby-plugin-intl";

import TnFlag from '../../components/TnFlag';
import { computeWaveShapeHeight } from '../../utils/helpers';

const addressGoogleMap = (lang = 'en') => {
    const address = `https://www.google.com/maps/place/Iheb+Center,+Avenue+de+Carthage,+Sfax+3027/@34.741526,10.757868,17z/data=!4m5!3m4!1s0x1301d2d44ad3011d:0x77d02de7926469a6!8m2!3d34.7415093!4d10.7577925?hl=${lang}`
    return address;
}

const Contact = ({width, breakPoints}) => {
    const {w576AndUp, w768AndUp} = breakPoints;
    const intl = useIntl();

    return (
        <>
            <div id="contact" className={`py-${w576AndUp ? '11' : '8'} position-relative`} data-bg-img="assets/images/bg/03.png">
                <div className="shape-1" style={{ height: computeWaveShapeHeight(width), overflow: 'hidden' }}>
                    <svg viewBox="0 0 500 150" preserveAspectRatio="none" style={{ height: '100%', width: '100%' }}>
                        <path d="M0.00,49.98 C150.00,150.00 271.49,-50.00 500.00,49.98 L500.00,0.00 L0.00,0.00 Z" style={{ stroke: 'none', fill: '#060927' }} />
                    </svg>
                </div>
            </div>
            <div className="position-relative">
                <div className="container">
                    <div className="row  text-center">
                        <div className="col">
                            <h1>
                                <FormattedMessage id="contact.title" />
                            </h1>
                        </div>
                    </div>
                </div>
            </div>
            <div className="page-content">
                <section className="pt-5 pt-md-11">
                    <div className="container">
                        <div className="row text-center">
                            <div className="col-lg-4 col-md-6 mb-5 mb-md-0 order-lg-0">
                                <div>
                                    <svg className="feather feather-mail" xmlns="http://www.w3.org/2000/svg" width={50} height={50} viewBox="0 0 24 24" fill="none" stroke="#1360ef" strokeWidth={1} strokeLinecap="round" strokeLinejoin="round"><path d="M4 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2z" /><polyline points="22,6 12,13 2,6" /></svg>
                                    <h4 className="mt-2 mt-md-5">
                                        <FormattedMessage id="contact.email" />
                                    </h4>
                                    <a href="mailto:contact@devmastery.tech">contact@devmastery.tech</a>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6 mb-5 mb-md-0 order-lg-2">
                                <div>
                                    <svg className="feather feather-phone-call" xmlns="http://www.w3.org/2000/svg" width={50} height={50} viewBox="0 0 24 24" fill="none" stroke="#1360ef" strokeWidth={1} strokeLinecap="round" strokeLinejoin="round"><path d="M15.05 5A5 5 0 0 1 19 8.95M15.05 1A9 9 0 0 1 23 8.94m-1 7.98v3a2 2 0 0 1-2.18 2 19.79 19.79 0 0 1-8.63-3.07 19.5 19.5 0 0 1-6-6 19.79 19.79 0 0 1-3.07-8.67A2 2 0 0 1 4.11 2h3a2 2 0 0 1 2 1.72 12.84 12.84 0 0 0 .7 2.81 2 2 0 0 1-.45 2.11L8.09 9.91a16 16 0 0 0 6 6l1.27-1.27a2 2 0 0 1 2.11-.45 12.84 12.84 0 0 0 2.81.7A2 2 0 0 1 22 16.92z" /></svg>
                                    <h4 className="mt-2 mt-md-5">
                                        <FormattedMessage id="contact.phone" />
                                    </h4>
                                    <div className="d-flex align-items-center justify-content-center">
                                        <TnFlag/>{' '}<a className="ml-1" href="tel:+21622585016"><span style={{opacity: 0.8}}>+216</span> 22 585 016</a>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-12 mb-5 mb-md-0 order-lg-1">
                                <div>
                                    <svg className="feather feather-map-pin" xmlns="http://www.w3.org/2000/svg" width={50} height={50} viewBox="0 0 24 24" fill="none" stroke="#1360ef" strokeWidth={1} strokeLinecap="round" strokeLinejoin="round"><path d="M21 10c0 7-9 13-9 13s-9-6-9-13a9 9 0 0 1 18 0z" /><circle cx={12} cy={10} r={3} /></svg>
                                    <h4 className="mt-2 mt-md-5">
                                        <FormattedMessage id="contact.address.title" />
                                    </h4>
                                    <a href={addressGoogleMap(intl.locale)} target="_blank" rel="noreferrer noopener">
                                        <FormattedHTMLMessage id="contact.address.name" />
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className="row justify-content-center mt-3 mt-md-5">
                        <div className="col-lg-6 col-md-12">
                        <iframe 
                            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1639.2790639597963!2d10.7567685419291!3d34.74153083844225!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1301d2d44ad3011d%3A0x77d02de7926469a6!2sIheb%20Center%2C%20Avenue%20de%20Carthage%2C%20Sfax%203027!5e0!3m2!1sen!2stn!4v1622255957774!5m2!1sen!2stn" 
                            width="100%" 
                            style={{
                                border: "2px solid #eee", 
                                minHeight: 300,
                                boxShadow: "rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px"
                            }} 
                            allowFullScreen="" 
                            loading="lazy"
                        >
                        </iframe>
                        </div>
                        </div>
                    </div>
                </section>
            </div>
        </>
    );
}

export default Contact;