export const computeDuration = (distance) => {
    const absDistance = Math.abs(distance);
    // console.log(absDistance);
    if (absDistance === 0) return 0;
    if (absDistance < 100) return 500;
    if (absDistance < 1000) return 1000;
    return (5000/9000)*absDistance;
}

export const computeWaveShapeHeight = (width) => {
    if (width < 768) return '100px';
    if (width < 992) return '150px';
    return '200px';
}