import React from 'react';

const ReactIcon = ({special}) => {
    return (
        <span 
        style={{
            boxSizing: "border-box", 
            WebkitTapHighlightColor: "transparent",
            display: "block",
            font: `${special ? "20px": "35px"} helvetica neue,open sans,sans-serif`,
            fontWeight: "300",
            //color: "#444",
            color: "#1D1D33",
            width: "100%",
            height: "100%"
        }} 
        >Express</span>
    )
}

export default ReactIcon;