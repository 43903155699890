import React, { useState, useEffect} from 'react';

const RightArrowWithParagraph = ({animation}) => {
    return (
        <div>
        <div>
        <i 
        style= {
            {
            border: "solid darkcyan", 
            borderWidth: "0 3px 3px 0", 
            display: "inline-block", 
            padding: "3px", 
            transform: "rotate(-45deg)", 
            WebkitTransform: "rotate(-45deg)"
            }
        }>     
        </i>
        <div 
        className="greyFakeDiv" 
        style= {
            {
                animation: animation && animation,
                display: "inline-block",
                margin: "0px 0px 0px 20px",
                height: "9px"
            }
        }></div>
        </div>
        </div>
    )
}


const LeftArrowWithPointAndParagraph = ({animation}) => {
    return (
        <div>
        <div>
            <i 
            style= {
                {
                border: "solid darkcyan", 
                borderWidth: "0 3px 3px 0", 
                display: "inline-block", 
                padding: "3px", 
                transform: "rotate(135deg)", 
                WebkitTransform: "rotate(135deg)",
                marginLeft: "5px"
                }
            }
            ></i>
            <span style={{display: "inline-block", width: "3px", height: "3px", background: "darkcyan", position: "relative", bottom: "3.0px", right: "3.8px", borderRadius: "50%"}}></span>
            <div 
            className="greyFakeDiv" 
            style= {
                {
                    animation: animation && animation,
                    display: "inline-block",
                    margin: "0px 0px 0px 13px",
                    height: "9px"
                }
            }></div>
        </div>
        </div>
    )
}


const Input = () => {
    return (
        <div>
            <div>
                <i 
                style= {
                    {
                    border: "solid darkcyan", 
                    borderWidth: "0 3px 3px 0", 
                    display: "inline-block", 
                    padding: "3px", 
                    transform: "rotate(-45deg)", 
                    WebkitTransform: "rotate(-45deg)"
                    }
                }>     
                </i>
                <input autoFocus className="inputConsole" style={{width: "80%", marginLeft: "20px", backgroundColor: "rgb(2, 32, 60)", border: "rgb(2, 32, 60)", color: "green", caretColor: "green", fontFamily: "monospace", fontWeight: 'bold'}} name="Text1"></input>
            </div> 
        </div>
    )
}

const OneLineParagraph = ({animation}) => {
    return (
        <div 
        className="greyFakeDiv" 
        style= {
            {
                animation: animation && animation,
                margin: "0px 0px 0px 29px",
                height: "9px"
            }
        }></div>
    )
}


const ThirdEditorContent = ({ShowSpinnerAgain, ShowOutput, click}) => {
    const [headerAndavatarIsShowed, setHeaderAndavatarIsShowed] = useState(false);
    const [postsIsShowed, setPostsIsShowed] = useState(false);
    useEffect(() => {
        setTimeout(() => {
            setHeaderAndavatarIsShowed(true);
        }, 3000);
        setTimeout(() => {
            setPostsIsShowed(true);
        }, 4700);

    }, [])
    return (
        ShowSpinnerAgain
        ? <Input />
        : ((ShowOutput && !ShowSpinnerAgain && click)
            ? (
            <>
            <RightArrowWithParagraph animation="greyFakeDiv1 1000ms forwards" />
            <LeftArrowWithPointAndParagraph animation="greyFakeDiv1 1000ms forwards" />
            <OneLineParagraph animation="greyFakeDiv1 1000ms forwards" />
            <hr style={{backgroundColor: "rgb(21, 49, 75)", margin: "20px 0 10px 0"}} />
            <Input />
            </>
            )
            : (
        <>
            {
                headerAndavatarIsShowed && <RightArrowWithParagraph animation="greyFakeDiv1 1000ms 500ms forwards" />
            }
            
            {
                postsIsShowed && (
                    <>
                    <LeftArrowWithPointAndParagraph animation="greyFakeDiv1 1000ms 500ms forwards" />
                    <OneLineParagraph animation="greyFakeDiv1 1000ms 1500ms forwards" />
                    </>
                )
            }
            
            {
                (headerAndavatarIsShowed || postsIsShowed) && <hr style={{backgroundColor: "rgb(21, 49, 75)", margin: "20px 0 10px 0"}} />
            }
            
            <Input />
            
        </>
        )
        )
    )
}

export default ThirdEditorContent;