import React, { useEffect, useRef } from 'react';
import './cube.css';

import htmlAndCss from './images/html-and-css.png';
import nodeJs from './images/node-js.png';
import mongoDB from './images/mongo-db.png';
import javascript from './images/javascript.png';
import redux from './images/redux.png';
import reactJs from './images/react-js.png';

export default function () {
  const _3dBox = useRef(null);
  let i = 1;

  const arr = [
    'rotate(0deg)', // front
    'rotateY(90deg)', // left
    'rotateY(180deg)', // back
    'rotateY(-90deg)', // right
    'rotateX(-90deg)', // top
    'rotateX(-270deg)'] // bottom
  useEffect(() => {

    

      if (true) { // show progSpinCube 
        setInterval(() => {
          if (i >= 1 && i <= 5) {
            if (_3dBox.current) { // fix error
            _3dBox.current.style.transform = arr[i]
            i++;
            }
          } else {
            if (_3dBox.current) { // fix error
            _3dBox.current.style.transform = arr[0];
            i = 1;
            }
          }
        }, 2000);
      } else { // show autoSpinCube
        _3dBox.current.style.animation = 'spin 45s linear infinite';
      }

    

  }, [])

  return (
    <>
      <div className="cubeContainer">
        <div className="space3d">
          <div ref={_3dBox} className="_3dbox">
            <div className="_3dface _3dfaceFront">
              <img className="rightImg" src={htmlAndCss} alt="html-and-css" />
            </div>
            <div className="_3dface _3dfaceTop">
              <img className="topImg" src={nodeJs} alt="node-js" />
            </div>
            <div className="_3dface _3dfaceBottom">
              <img className="backImg" src={mongoDB} alt="mongoDB" />
            </div>
            <div className="_3dface _3dfaceLeft">
              <img className="bottomImg" src={javascript} alt="js" />
            </div>
            <div className="_3dface _3dfaceRight">
              <img className="leftImg" src={redux} alt="redux" />
            </div>
            <div className="_3dface _3dfaceBack">
              <img className="frontImg" src={reactJs} alt="react-js" />
            </div>
          </div>
        </div>
      </div>
      <br />
    </>
  )
}