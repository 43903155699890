import React, { Component } from 'react';
import {Link} from 'react-scroll'
import { FormattedHTMLMessage, FormattedMessage } from "gatsby-plugin-intl"

import Editor from '../../components/editors/index'
import { computeWaveShapeHeight } from '../../utils/helpers'

const Herosection2 = ({width, height, breakPoints}) => {
  const {w576AndUp, w768AndUp, w992AndUp} = breakPoints;

  return (
    <section id="home" className={`custom-pt-${w576AndUp ? '1' : '0_75'} custom-pb-${w992AndUp ? '3' : (w768AndUp ? '2_5' : (w576AndUp ? '2' : '1'))} bg-primary position-relative parallaxie`} data-bg-img={require(`../../assets/images/bg/03.png`)}>
      <div className="container">
        <div className="row align-items-center">
          <div className="col-12 col-lg-4 col-xl-4">
            {/* Heading */}
            <h1 className="display-5 text-white">
              <FormattedHTMLMessage id="big_title" />
          </h1>
            {/* Text */}
            {/* <p className="lead text-light">Build a Beautiful, Clean &amp; Modern Design website with flexible Bootstrap components.</p> */}
            <p className="lead font-weight-normal text-light">
              <FormattedHTMLMessage id="first_subtitle" />
            </p>
            <p className="lead h6 text-light" style={{ fontSize: '1rem' }}>
            <FormattedHTMLMessage id="second_subtitle" />
            </p>
            {/* Buttons */} 
            <Link  to="form" duration={5000} offset={-84} smooth={true}>
              <button className="btn btn-outline-light mr-1 mt-3">
              <FormattedMessage id="register_btn" />
              </button>
            </Link>
              
            {/* <a href="http://vimeo.com/99025203" className="btn text-white popup-vimeo"> <i className="la la-play-circle mr-1 ic-3x align-middle" /> Watch Video</a> */}
          </div>
          <div className="col-12 col-lg-8 col-xl-8 mr-auto mb-0 mt-5 mt-lg-0" style={{ perspective: "1000px", minHeight: "20vh" }}>
            {/* Image */}
            {
              (width && width < 768) 
                    ? <img src={require(`../../assets/images/mobile.png`)} className="img-fluid editor-mobile" alt="editor-mobile" />
                    : <Editor /> 
            }
            {/* <img src={require(`../../assets/images/hero/02.png`)} className="img-fluid" alt="..." /> */}
          </div>
        </div>
        {/* / .row */}
      </div>
      {/* / .container */}
      <div className="shape-1 bottom" style={{ height: computeWaveShapeHeight(width), overflow: 'hidden' }}>
        <svg viewBox="0 0 500 150" preserveAspectRatio="none" style={{ height: '100%', width: '100%' }}>
          <path d="M0.00,49.98 C150.00,150.00 349.20,-50.00 500.00,49.98 L500.00,150.00 L0.00,150.00 Z" style={{ stroke: 'none', fill: 'rgb(6, 9, 39)' }} />
        </svg>
      </div>
    </section>

  )
}

export default Herosection2;