
import React from "react";
import PhoneInput from 'react-phone-input-2';
import { FormGroup, Label, Input, FormFeedback, FormText } from 'reactstrap';
import 'react-phone-input-2/lib/bootstrap.css';
  

export default function PhoneInputField({ propsFromFormik, label, refName, type }) {
  const { field, form, meta } = propsFromFormik;
  // console.log(form);
  // console.log(form.errors.phoneNumber);
  // console.log(meta);
  const error = meta.touched && meta.error && true
  return (
    <>
      <Label className="text-muted" htmlFor={refName}>{label}</Label>
      <PhoneInput
        country={'tn'}
        excludeCountries={['il']}
        enableSearch
        isValid={(value, country) => {
          //console.log(value);
          //console.log(country);

          return error ? false : true;
        }}
        onBlur={() => form.setFieldTouched(field.name)}
        value={form.values.phoneNumber}
        onChange={(e) => form.setFieldValue(field.name, e)}
        inputStyle={{width: '100%', marginBottom: meta.touched && meta.error ? 0 : 19}}
        masks={{tn: '.. ... ...'}}

        />
        <FormFeedback style={{marginTop: 0, display: 'block'}}>{meta.touched && meta.error && meta.error}</FormFeedback>
        </>

  );
}
