import React from 'react';
import { FormGroup, Label, Input, FormFeedback, FormText } from 'reactstrap';
import { FormattedMessage, useIntl } from "gatsby-plugin-intl";

const SelectInput = ({ propsFromFormik, label, refName, options }) => {
    const { field, form, meta } = propsFromFormik;
    const intl = useIntl();

    const handlePress = (event) => {
        if (event.key === 'Enter') {
            // console.log('enter press here! ')
            event.target.blur()
        }
    }

    return (
        <>
            <label className="text-muted" htmlFor={refName}>{label}</label>
            <select 
                className={`custom-select form-control ${meta.touched && meta.error ? "is-invalid" : ""}`} 
                id={refName} 
                aria-describedby={`${refName}Feedback`}
                defaultValue=""
                name={refName}
                style={{marginBottom: meta.touched && meta.error ? 0 : 19}} 
                //onKeyPress={handlePress}
                {...field}
            >
                <option disabled value="">
                    {intl.formatMessage({ id: "form.choose" })}...
                </option>
                {
                    options.map((option, i) => <option key={i} value={option.dashedName}>{option.name}</option>)
                }
            </select>
            <div style={{marginTop: 0}} id="validationServer04Feedback" className="invalid-feedback">
                {meta.touched && meta.error && meta.error}
            </div>
        </>
    )
}

export default SelectInput;