import React from 'react';
import { FormattedHTMLMessage, FormattedMessage, useIntl } from "gatsby-plugin-intl";

const EditorPortion = ({portion, imgName, num, titleId, descriptionId, breakPoints}) => {
    const isRight = portion === 'right';
    const {w576AndUp, w768AndUp, w992AndUp} = breakPoints;
    return (
        <div className={`row align-items-center justify-content-between mb-1 mb-md-4 mb-lg-10`}>
            <div 
                className={`col-12 col-lg-6 order-1 ${isRight ? '' : 'order-lg-0'} mb-6 mb-lg-0`} 
                style={{ perspective: "900px" }}
            >
                <img 
                    data-inviewport={`anim-${isRight ? 'right' : 'left'}-portion`} 
                    src={require(`../assets/images/${imgName}.png`)} 
                    alt="Image" 
                    className={`img-fluid ${isRight ? 'editorPortionRight' : 'editorPortionLeft'}`} 
                />
                {
                    !w992AndUp && (
                        <div>
                            <p className="lead mb-0 mt-4 text-justify">
                                <FormattedMessage id={descriptionId} />
                            </p>
                        </div>
                    )
                }
            </div>
            <div className="col-12 col-lg-6 col-xl-5">
                <div>
                    <h2> 
                        <span className="badge badge-primary-soft p-2">
                        {num}
                        </span>
                    </h2>
                    <h4 className="mt-0 mt-md-3 text-dark">
                        <FormattedMessage id={titleId} />
                    </h4>
                    {
                        w992AndUp && (
                            <p className="lead mb-0">
                                <FormattedMessage id={descriptionId} />
                            </p>
                        )
                    }
                </div>
            </div>
        </div>
    )
}

export default EditorPortion;