import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

/*
 * This component is built using `gatsby-image` to automatically serve optimized
 * images with lazy loading and reduced file sizes. The image is loaded using a
 * `useStaticQuery`, which allows us to load the image from directly within this
 * component, rather than having to pass the image data down from pages.
 *
 * For more information, see the docs:
 * - `gatsby-image`: https://gatsby.dev/gatsby-image
 * - `useStaticQuery`: https://www.gatsbyjs.com/docs/use-static-query/
 */

const LoaderImg = () => {
  const data = useStaticQuery(graphql`
    query {
      placeholderImage: file(relativePath: { eq: "blacklogoicon.png" }) {
        childImageSharp {
          fixed(width: 160) {
            ...GatsbyImageSharpFixed_withWebp_noBase64
          }
        }
      }
    }
  `)

  if (!data?.placeholderImage?.childImageSharp?.fixed) {
    return <div>Picture not found</div>
  }

  return <Img
  fixed={data.placeholderImage.childImageSharp.fixed} 
  alt='DevMastery'
  //style={{marginLeft: '9px', overflow: 'unset'}}
  //imgStyle={{marginLeft: '0px', width: 'auto', position: 'unset'}}
  //imgStyle={{}}
  //style={{marginLeft: '10px'}}
  //durationFadeIn={0}
  fadeIn={false}
  />
}

export default LoaderImg
