import React from 'react';
import { FormGroup, Label, Input, FormFeedback, FormText } from 'reactstrap';

const SimpleInput = ({propsFromFormik, label, refName, type}) => {
    const { field, form, meta } = propsFromFormik;

    const handlePress = (event) => {
        if (event.key === 'Enter') {
            // console.log('enter press here! ')
            event.target.blur()
        }
    }

    return (
        <>
            <Label className="text-muted" htmlFor={refName}>{label}</Label>
            <Input
                type={type}
                className="form-control input-1"
                id={refName} 
                placeholder={label}
                name={refName}
                //required="required"
                invalid={meta.touched && meta.error && true}
                onKeyPress={handlePress}
            // data-error="Firstname is required."
                style={{marginBottom: meta.touched && meta.error ? 0 : 19}} 
                {...field}
            />
            <FormFeedback style={{marginTop: 0}}>{meta.touched && meta.error && meta.error}</FormFeedback>
            {/* <div className="help-block with-errors" /> */}
        </>
    )
}

export default SimpleInput;