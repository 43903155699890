import React, { Component } from 'react';

import AliceCarousel from 'react-alice-carousel';

const responsive = {
    0: { items: 1 },
    568: { items: 3 },
    1024: { items: 6 },
};

const items = [
    <div className="item">
        <div className="clients-logo">
            <img className="img-fluid" src={require(`../../assets/images/client/07.png`)} alt="" />
        </div>
    </div>,
    <div className="item">
        <div className="clients-logo">
            <img className="img-fluid" src={require(`../../assets/images/client/08.png`)} alt="" />
        </div>
    </div>,
    <div className="item">
        <div className="clients-logo">
            <img className="img-fluid" src={require(`../../assets/images/client/09.png`)} alt="" />
        </div>
    </div>,
    <div className="item">
        <div className="clients-logo">
            <img className="img-fluid" src={require(`../../assets/images/client/10.png`)} alt="" />
        </div>
    </div>,
    <div className="item">
        <div className="clients-logo">
            <img className="img-fluid" src={require(`../../assets/images/client/11.png`)} alt="" />
        </div>
    </div>,
    <div className="item">
        <div className="clients-logo">
            <img className="img-fluid" src={require(`../../assets/images/client/12.png`)} alt="" />
        </div>
    </div>,
];

class Clientlogo extends Component {
    render() {
        return (
            <AliceCarousel
                mouseTracking
                items={items}
                responsive={responsive}
                autoPlay
                autoPlayInterval={2000}
                animationDuration={2000}
                disableDotsControls
                disableButtonsControls
                infinite
            />
        );
    }
}

export default Clientlogo;