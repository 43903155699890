import React from 'react';

const TnFlag = () => {
    return (
  <svg /*width="30"*/ height="16" viewBox="-60 -40 120 80" xmlns="http://www.w3.org/2000/svg" fill="#e70013">
    <path d="M-60-40H60v80H-60z"/>
    <circle fill="#fff" r="20"/>
    <circle r="15"/>
    <circle fill="#fff" cx="4" r="12"/>
    <path d="M-5 0l16.281-5.29L1.22 8.56V-8.56L11.28 5.29z"/>
  </svg>
    )
  }
  
  export default TnFlag;