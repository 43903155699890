import React, { Component, useEffect, useState } from 'react';
import { FormattedHTMLMessage, FormattedMessage, useIntl } from "gatsby-plugin-intl"
import Header3 from '../layout/header/header3';
import Herosection2 from '../widgets/herosection/herosection2';
import About from '../widgets/index/about';
import Clientlogo from '../widgets/common/clientlogo';
import Experience from '../widgets/featurebox/experience';
import Testimonial2 from '../widgets/testimonial/testimonial2';
import Counter2 from '../widgets/counter/counter2';
import AccordionDemo from '../widgets/featuare/accordiondemo';
import Blog2 from '../widgets/blog/blog2';
import ProfessionalForm from '../widgets/form/professionalform';
import Footer from '../layout/footer/footer';
import Contact from '../layout/contact/contact';
import Scrolltop from '../layout/back-to-top';
import Loader from '../components/Loader/Loader'
import Services from '../widgets/index/services';
import Cube from '../components/cube/Cube';
import HtmlIcon from '../components/svg-icons/HtmlIcon';
import CssIcon from '../components/svg-icons/CssIcon';
import JavaScriptIcon from '../components/svg-icons/JavaScriptIcon';
import TerminalIcon from '../components/svg-icons/TerminalIcon';
import GitIcon from '../components/svg-icons/GitIcon';
import ReactIcon from '../components/svg-icons/ReactIcon';
import ExpressIcon from '../components/svg-icons/ExpressIcon';
import NodeIcon from '../components/svg-icons/NodeIcon';
import MongoDBIcon from '../components/svg-icons/MongoDBIcon';
import EditorPortion from '../components/EditorPortion';
import { computeWaveShapeHeight } from '../utils/helpers';

let onLoadPage = true;

function useWindowSize() {
  const [size, setSize] = useState([typeof window !== 'undefined' && window.innerHeight, typeof window !== 'undefined' && window.innerWidth]);
  useEffect(() => {
    const handleResize = () => {
      setSize([window.innerHeight, window.innerWidth])
    }
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    }
  }, [])
  return size;
}

const Index3 = (props) => {

  const [loader, setLoader] = React.useState(true);
  const [height, width] = useWindowSize();
  const w576AndUp = width >= 576;
  const w768AndUp = width >= 768;
  const w992AndUp = width >= 992;
  const w1200AndUp = width >= 1200;
  const breakPoints = {
    w576AndUp,
    w768AndUp,
    w992AndUp,
    w1200AndUp
  }
  
  const intl = useIntl();

  React.useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  React.useEffect(() => {
    if (loader == true) {
      setTimeout(() => {
        setLoader(false);
      }, 2000);
    }
    if (onLoadPage) { onLoadPage = false }
  })

  useEffect(() => {
    if (!loader) {
      const inViewport = (entries, observer) => {
        entries.forEach(entry => {
          entry.target.classList.toggle("is-inViewport", entry.isIntersecting);
        });
      };

      const obsOptions = {
        root: null,
        rootMargin: '0px',
        threshold: 1,
      }; //See: https://developer.mozilla.org/en-US/docs/Web/API/Intersection_Observer_API#Intersection_observer_options
      const Obs = new IntersectionObserver(inViewport, obsOptions);

      // Attach observer to every [data-inviewport] element:
      const ELs_inViewport = document.querySelectorAll('[data-inviewport]');
      ELs_inViewport.forEach(EL => {
        Obs.observe(EL);
      });
    }
  }, [loader])

  return (
    <div className="page-wrapper">
      {(loader && onLoadPage) ? (
        // <div>
        // <header className={` ${"site-header  navbar-dark"}`}>
        // <div id="ht-preloader">
        //     <div className="loader clear-loader">
        //         <span />
        //         <p style={{marginBottom: 0}}>BootsLand</p>
        //     </div>
        // </div>
        // </header>
        // </div>
        <Loader />
      ) : (
          <>
            <div>
              {/*header start*/}
              <Header3 />
              {/*header end*/}
              {/*hero section start*/}
              <Herosection2 width={width} height={height} breakPoints={breakPoints} />
              {/*hero section end*/}
              {/*testimonial start*/}
              <section className={`custom-pb-${w992AndUp ? '2' : (w768AndUp ? '1_5' : (w576AndUp ? '1' : '0_75'))} position-relative bg-dark parallaxie pt-0`} data-bg-img="assets/images/bg/02.png">
                {
                  process.env.SHOW_PARTNERS === 'yes' && (
                    <>
                      {/*client start*/}
                      {/* <section className="p-0"> */}
                      <div className="container mb-8">
                        <h5 className="text-light text-center" style={{ opacity: 0.3 }}>
                          <FormattedMessage id="recruiting_partners" />
                        </h5>
                        <div className="row">
                          <div className="col-12">
                            <Clientlogo logoitems={6} />
                          </div>
                        </div>
                      </div>
                      {/* </section> */}
                      {/*client end*/}
                    </>
                  )
                }
                <div className="container">
                  <div className="row justify-content-center text-center">
                    <div className="col-12 col-md-12 col-lg-8 mb-8">
                      <div>
                        <p className="lead text-justify text-white mb-5 pb-0">
                          <FormattedMessage id="next_generation_school" />
                        </p>
                      </div>
                      <div>
                        <span className="badge badge-primary p-2">
                          <i className="las la-code ic-3x rotation" />
                        </span>
                        <h2 className="mt-4 text-white">
                          <FormattedMessage id="why_different.question" />
                        </h2>
                        <p className="lead mb-0 text-justify">
                          <FormattedHTMLMessage id="why_different.response" />
                        </p>
                      </div>
                    </div>
                    <div className="col-12 text-center">
                      <img src={require(`../assets/images/developers.gif`)} alt="developers" className="developers-img" style={{ maxWidth: "350px", marginBottom: "35px", borderRadius: "1rem" }} />
                    </div>
                  </div>
                  {/* / .row */}
                </div>
                {/* <div className="shape-1" style={{height: computeWaveShapeHeight(width), overflow: 'hidden'}}>
            <svg viewBox="0 0 500 150" preserveAspectRatio="none" style={{height: '100%', width: '100%'}}>
              <path d="M0.00,49.98 C150.00,150.00 271.49,-50.00 500.00,49.98 L500.00,0.00 L0.00,0.00 Z" style={{stroke: 'none', fill: '#fff'}} />
            </svg>
          </div> */}
                <div className="shape-1 bottom" style={{ height: computeWaveShapeHeight(width), overflow: 'hidden' }}>
                  <svg viewBox="0 0 500 150" preserveAspectRatio="none" style={{ height: '100%', width: '100%' }}>
                    <path d="M0.00,49.98 C150.00,150.00 349.20,-50.00 500.00,49.98 L500.00,150.00 L0.00,150.00 Z" style={{ stroke: 'none', fill: '#fff' }} />
                  </svg>
                </div>
              </section>
              {/*testimonial end*/}
              {/*body content start*/}
              <div className="page-content">
                {/*about start*/}
                {/* <About /> */}
                {/*about end*/}
                {/*how it work start*/}
                <section className="position-relative py-0">
                  <div className="container">
                    <div className="row justify-content-center text-center">
                      <div className="col-12 col-md-12 col-lg-8 mb-1 mb-lg-8 mb-lg-0">
                        <div className="mb-4 mb-sm-6 mb-md-8"> 
                          <span className="badge badge-primary-soft p-2 font-w-6">
                            <FormattedMessage id="work_manner" />
                          </span>
                          <h2 className="mt-3 font-w-5 mb-0">
                            <FormattedMessage id="learning_system" />
                          </h2>
                        </div>
                      </div>
                    </div>
                    {/* / .row */}
                    {
                      [
                        {
                          portion: "left",
                          imgName: "lab",
                          num: "01",
                          titleId: "choose_path.title",
                          descriptionId: "choose_path.description"
                        },
                        {
                          portion: "right",
                          imgName: "codeEditor",
                          num: "02",
                          titleId: "learn_by_doing.title",
                          descriptionId: "learn_by_doing.description"
                        },
                        {
                          portion: "left",
                          imgName: "testsOutput",
                          num: "03",
                          titleId: "instant_feedback.title",
                          descriptionId: "instant_feedback.description"
                        },
                        {
                          portion: "right",
                          imgName: "preview",
                          num: "04",
                          titleId: "learning_practice.title",
                          descriptionId: "learning_practice.description"
                        },
                        {
                          portion: "left",
                          imgName: "console",
                          num: "05",
                          titleId: "get_job.title",
                          descriptionId: "get_job.description"
                        },
                      ].map(({portion, imgName, num, titleId, descriptionId}, i) => (
                        <EditorPortion 
                          key={i}
                          portion={portion} 
                          imgName={imgName} 
                          num={num} 
                          titleId={titleId}
                          descriptionId={descriptionId}
                          breakPoints={breakPoints} 
                        />
                      ))
                    }
                  </div>
                  {/* <div className="shape-1" style={{height: computeWaveShapeHeight(width), overflow: 'hidden'}}>
                  <svg viewBox="0 0 500 150" preserveAspectRatio="none" style={{height: '100%', width: '100%'}}>
                    <path d="M0.00,49.98 C150.00,150.00 271.49,-50.00 500.00,49.98 L500.00,0.00 L0.00,0.00 Z" style={{stroke: 'none', fill: '#fff'}} />
                  </svg>
                </div>
                <div className="shape-1 bottom" style={{height: computeWaveShapeHeight(width), overflow: 'hidden'}}>
                  <svg viewBox="0 0 500 150" preserveAspectRatio="none" style={{height: '100%', width: '100%'}}>
                    <path d="M0.00,49.98 C150.00,150.00 349.20,-50.00 500.00,49.98 L500.00,150.00 L0.00,150.00 Z" style={{stroke: 'none', fill: '#fff'}} />
                  </svg>
                </div> */}
                </section>
                {/*how it work end*/}
                {/*service start*/}
                {/* <Experience /> */}
                {/*service end*/}
                {/*testimonial start*/}
                <section className={`custom-pt-${w992AndUp ? '2' : (w576AndUp ? '1_5' : '0_75')} custom-pb-${w992AndUp ? '2_5' : (w576AndUp ? "2" : "1")} position-relative bg-dark parallaxie`} data-bg-img="assets/images/bg/02.png">
                  {/* 
                  <div className="container">
                    <div className="row justify-content-center text-center">
                      <div className="col-12 col-md-12 col-lg-8 mb-8">
                        <div> <span className="badge badge-primary p-2">
                          <i className="la la-users ic-3x rotation" />
                        </span>
                          <h2 className="mt-4 text-white">Discover Our Client Feedback</h2>
                          <p className="lead mb-0">All types of businesses need access to development resources, so we give you the option to decide how much you need to use.</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <Testimonial2 /> 
                  */}



                  <div className="container">
                    <div className="row align-items-center justify-content-center">
                      <div className="col-12">
                        {/* <div className="mb-8">
                          <h2 className="font-w-6 text-white">One Platform, Deep Insight Why Choose Bootsland</h2>
                        </div> */}
                        <div className="mb-4 mb-sm-8"> 
                          <span style={{fontSize: '1.3rem'}} className="badge badge-primary-soft p-2 font-w-6">
                            <FormattedMessage id="b2b.badge" />
                          </span>
                          <br />
                            <p className="lead" style={{marginTop: 10}}>
                              <FormattedMessage id="b2b.first_p" />
                            </p>

                            <h2 className="mt-3 font-w-5 text-white">
                              <FormattedMessage id="b2b.title" />
                            </h2>

                            <p className="mb-0">
                              <FormattedMessage id="b2b.second_p" />
                            </p>
                        </div>
                          <div className="row justify-content-center">
                            <div className="col-lg-9 col-md-9 mt-6 mt-md-0">
                              <div className="d-flex align-items-start">
                                <div className="mr-3 p-3">
                                  <img style={{maxWidth: 'inherit'}} className="img-fluid" src={require(`../assets/images/icon/03.svg`)} alt="" />
                                </div>
                                <div>
                                  <h5 className="mb-3 text-primary">
                                    <FormattedMessage id="b2b.interactive_learning.title" />
                                  </h5>
                                  <p className={`mb-0 text-${w768AndUp ? 'left' : 'justify'}`}>
                                    <FormattedMessage id="b2b.interactive_learning.description" />
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-lg-6 col-md-6 mt-6">
                              <div className="d-flex align-items-start">
                                <div className="mr-3 p-3">
                                  <img style={{maxWidth: 'inherit'}} className="img-fluid" src={require(`../assets/images/icon/02.svg`)} alt="" />
                                </div>
                                <div>
                                  <h5 className="mb-3 text-primary">
                                    <FormattedMessage id="b2b.guidance.title" />
                                  </h5>
                                  <p className={`mb-0 text-${w768AndUp ? 'left' : 'justify'}`}>
                                    <FormattedMessage id="b2b.guidance.description" />
                                  </p>
                                </div>
                              </div>
                            </div>
                            <div className="col-lg-6 col-md-6 mt-6">
                              <div className="d-flex align-items-start">
                                <div className="mr-3 p-3">
                                  <img style={{maxWidth: 'inherit'}} className="img-fluid" src={require(`../assets/images/icon/01.svg`)} alt="" />
                                </div>
                                <div>
                                  <h5 className="mb-3 text-primary">
                                    <FormattedMessage id="b2b.proof_of_progress.title" />
                                  </h5>
                                  <p className={`mb-0 text-${w768AndUp ? 'left' : 'justify'}`}>
                                    <FormattedMessage id="b2b.proof_of_progress.description" />
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>





                    <div className="shape-1" style={{ height: computeWaveShapeHeight(width), overflow: 'hidden' }}>
                      <svg viewBox="0 0 500 150" preserveAspectRatio="none" style={{ height: '100%', width: '100%' }}>
                        <path d="M0.00,49.98 C150.00,150.00 271.49,-50.00 500.00,49.98 L500.00,0.00 L0.00,0.00 Z" style={{ stroke: 'none', fill: '#fff' }} />
                      </svg>
                    </div>
                    <div className="shape-1 bottom" style={{ height: computeWaveShapeHeight(width), overflow: 'hidden' }}>
                      <svg viewBox="0 0 500 150" preserveAspectRatio="none" style={{ height: '100%', width: '100%' }}>
                        <path d="M0.00,49.98 C150.00,150.00 349.20,-50.00 500.00,49.98 L500.00,150.00 L0.00,150.00 Z" style={{ stroke: 'none', fill: '#fff' }} />
                      </svg>
                    </div>
                </section>
                  {/*testimonial end*/}
                  {/*counter start*/}
                  <section id="ourProgarms" className="p-0 mb-6">
                    <div className="container">
                      <div className="row align-items-center justify-content-between">
                        <div className="col-12 col-lg-6 col-xl-5">
                          {/* <AccordionDemo /> */}
                          <div>
                            <span className="badge badge-primary-soft p-2">
                              <i className="la la-cubes ic-3x rotation" />
                            </span>
                            <h2 className="mt-3 mb-0">
                              <FormattedMessage id="paths_intro" />
                            </h2>
                          </div>
                        </div>
                        <div className="col-12 col-lg-6 mb-0 mt-11 mt-lg-0">
                          {/* <Counter2 /> */}
                          <Cube />
                        </div>
                      </div>
                    </div>
                  </section>
                  {/*counter end*/}
                  {/*about start*/}
                  <About
                    title="HTML & CSS"
                    description="path_description.html_css_path"
                    icons={[HtmlIcon, CssIcon]}
                    technologies={
                      [
                        "HTML Basics",
                        "CSS Basics",
                        "Semantic HTML",
                        "CSS Flexbox",
                        "CSS Grid",
                        "CSS Responsive",
                        "Advanced CSS",
                        "Visual Studio Code"
                      ]
                    }
                    width={width} height={height}
                    bg="gray"
                  />
                  <About
                    logoPosition="right"
                    title="JavaScript, Terminal & Git"
                    description="path_description.javascript"
                    icons={[JavaScriptIcon, TerminalIcon, GitIcon]}
                    technologies={
                      [
                        "JavaScript Basics",
                        "ECMAScript 6",
                        "Advanced JavaScript",
                        "Algorithmics",
                        "Command Line",
                        "Git and GitHub",
                        "DOM Manipulation",
                      ]
                    }
                    width={width} height={height}
                    fill="gray"
                  />
                  <About
                    title="Front-end with React"
                    description="path_description.frontend"
                    icons={[ReactIcon]}
                    technologies={
                      [
                        "Bootstrap 5",
                        "React Basics",
                        "React Hooks",
                        "React Routing",
                        "Advanced React",
                        "Create React App",
                        "Redux",
                      ]
                    }
                    width={width} height={height}
                    bg="gray"
                    rotate
                  />
                  <About
                    logoPosition="right"
                    title="Back-end with NodeJS, Express & MongoDB"
                    description="path_description.backend"
                    icons={[NodeIcon, ExpressIcon, MongoDBIcon]}
                    technologies={
                      [
                        "Node.js",
                        "NPM",
                        "Express.js",
                        "MongoDB",
                        "Mongoose",
                        "Authentication",
                        "Template Engines",
                        "Build APIs",
                        "Build MERN Stack App"
                      ]
                    }
                    width={width} height={height}
                    fill="gray"
                  />
                  <About
                    title="FullStack development"
                    description="path_description.fullstack"
                    special
                    last
                    // icons={{HtmlIcon, CssIcon, JavaScriptIcon, ReactIcon, NodeIcon, ExpressIcon, MongoDBIcon}}
                    icons={[[HtmlIcon], [CssIcon, JavaScriptIcon], [TerminalIcon, ReactIcon, GitIcon], [NodeIcon, ExpressIcon], [MongoDBIcon]]}
                    technologies={
                      [
                        "HTML & CSS",
                        "Javascript, Terminal & Git",
                        "Front-end with React",
                        "Back-end with NodeJS, Express & MongoDB"
                      ]
                    }
                    width={width} height={height}
                    bg="gray"
                  />
                  {/*about end*/}
                  {/*form start*/}
                  <section className={`custom-pt-${w992AndUp ? '2' : (w576AndUp ? '1': '0_75')} custom-pb-0_5 position-relative bg-dark parallaxie`} data-bg-img="assets/images/bg/02.png">
                    <div className="container">
                      <div className="row align-items-center justify-content-between">
                        <div className="col-12 col-lg-6 col-xl-5 mb-8 mb-lg-0 order-lg-1">
                          <div> <span className="badge badge-primary-soft p-2">
                            <i className="las la-rocket ic-3x" />
                          </span>
                            <h2 className="mt-3 text-white">
                              <FormattedMessage id="investing.title" />
                            </h2>
                            <p className="lead mb-0">
                              <FormattedMessage id="investing.subtitle" />
                            </p>
                          </div>
                          {/* <div className="mt-5">
                          <div className="mb-3">
                            <div className="d-flex align-items-center">
                              <div className="badge-primary-soft rounded p-1">
                                <svg width={20} height={20} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="feather feather-check">
                                  <polyline points="20 6 9 17 4 12" />
                                </svg>
                              </div>
                              <p className="mb-0 ml-3">Create For Designer</p>
                            </div>
                          </div>
                          <div className="mb-3">
                            <div className="d-flex align-items-center">
                              <div className="badge-primary-soft rounded p-1">
                                <svg width={20} height={20} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="feather feather-check">
                                  <polyline points="20 6 9 17 4 12" />
                                </svg>
                              </div>
                              <p className="mb-0 ml-3">Create For Developer</p>
                            </div>
                          </div>
                          <div>
                            <div className="d-flex align-items-center">
                              <div className="badge-primary-soft rounded p-1">
                                <svg width={20} height={20} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="feather feather-check">
                                  <polyline points="20 6 9 17 4 12" />
                                </svg>
                              </div>
                              <p className="mb-0 ml-3">Create For Freelancer</p>
                            </div>
                          </div>
                        </div> */}
                        </div>
                        <div className="col-12 col-lg-6 col-xl-6">
                          <ProfessionalForm />
                        </div>
                      </div>
                    </div>
                    <div className="shape-1" style={{ height: computeWaveShapeHeight(width), overflow: 'hidden' }}>
                      <svg viewBox="0 0 500 150" preserveAspectRatio="none" style={{ height: '100%', width: '100%' }}>
                        <path d="M0.00,49.98 C150.00,150.00 271.49,-50.00 500.00,49.98 L500.00,0.00 L0.00,0.00 Z" style={{ stroke: 'none', fill: width < 992 ? '#eee' : '#fff' }} />
                      </svg>
                    </div>
                  </section>
                  {/*form end*/}
              </div>
                {/*body content end*/}
              </div>
              <Contact width={width} breakPoints={breakPoints} />
              <Footer width={width} breakPoints={breakPoints} />
              <Scrolltop />
          </>
        )}


          </div>
        );


}

export default Index3;