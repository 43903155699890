import React, { Component } from 'react';
import { IntlContextConsumer, changeLocale } from "gatsby-plugin-intl"
import navLinks from '../../api/NavLinks';
import { Link } from 'gatsby';
import {Link as LinkScroll} from 'react-scroll'
import { FormattedMessage } from "gatsby-plugin-intl"
import {
    Collapse,
    Navbar,
    NavbarToggler,
    NavbarBrand,
    Nav,
    NavItem,
    NavLink,
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    NavbarText
} from 'reactstrap'

import { computeDuration } from '../../utils/helpers';

import logo from '../../images/blacklogoNavbar.svg'



const languageName = {
    en: {
        en: "English",
        fr: "Anglais",
        code: "gb"
    },
    fr: {
        en: "French",
        fr: "Français",
        code: "fr"
    }
}



const GlobeIcon = ({ width = 24, height = 24 }) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={width}
        height={height}
        fill="currentColor"
        className="bi bi-globe"
        viewBox="0 0 16 16"
    >
        <path d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8zm7.5-6.923c-.67.204-1.335.82-1.887 1.855A7.97 7.97 0 0 0 5.145 4H7.5V1.077zM4.09 4a9.267 9.267 0 0 1 .64-1.539 6.7 6.7 0 0 1 .597-.933A7.025 7.025 0 0 0 2.255 4H4.09zm-.582 3.5c.03-.877.138-1.718.312-2.5H1.674a6.958 6.958 0 0 0-.656 2.5h2.49zM4.847 5a12.5 12.5 0 0 0-.338 2.5H7.5V5H4.847zM8.5 5v2.5h2.99a12.495 12.495 0 0 0-.337-2.5H8.5zM4.51 8.5a12.5 12.5 0 0 0 .337 2.5H7.5V8.5H4.51zm3.99 0V11h2.653c.187-.765.306-1.608.338-2.5H8.5zM5.145 12c.138.386.295.744.468 1.068.552 1.035 1.218 1.65 1.887 1.855V12H5.145zm.182 2.472a6.696 6.696 0 0 1-.597-.933A9.268 9.268 0 0 1 4.09 12H2.255a7.024 7.024 0 0 0 3.072 2.472zM3.82 11a13.652 13.652 0 0 1-.312-2.5h-2.49c.062.89.291 1.733.656 2.5H3.82zm6.853 3.472A7.024 7.024 0 0 0 13.745 12H11.91a9.27 9.27 0 0 1-.64 1.539 6.688 6.688 0 0 1-.597.933zM8.5 12v2.923c.67-.204 1.335-.82 1.887-1.855.173-.324.33-.682.468-1.068H8.5zm3.68-1h2.146c.365-.767.594-1.61.656-2.5h-2.49a13.65 13.65 0 0 1-.312 2.5zm2.802-3.5a6.959 6.959 0 0 0-.656-2.5H12.18c.174.782.282 1.623.312 2.5h2.49zM11.27 2.461c.247.464.462.98.64 1.539h1.835a7.024 7.024 0 0 0-3.072-2.472c.218.284.418.598.597.933zM10.855 4a7.966 7.966 0 0 0-.468-1.068C9.835 1.897 9.17 1.282 8.5 1.077V4h2.355z" />
    </svg>
)


class Header3 extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isOpen: false,
            visible: false,
            loader: true,
            headername: window.location.pathname
        }
        this.toggle = this.toggle.bind(this);
        this.handleScroll = this.handleScroll.bind(this);
    }
    toggle() {
        this.setState({
            isOpen: !this.state.isOpen
        });
    }
    handleClick(event) {
        var elems = document.querySelectorAll(".childsubmenu");
        [].forEach.call(elems, function (el) {
            el.classList.remove("show");
        });
    }
    componentDidMount() {
        window.addEventListener('scroll', this.handleScroll);
    }
    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll);
    }

    handleScroll() {

        var scrollTop = (document.documentElement && document.documentElement.scrollTop) ||
            document.body.scrollTop;
        if (scrollTop > 100) {
            this.setState({
                visible: true
            });
        }
        else {
            this.setState({
                visible: false
            });
        }

    }

    render() {
        const { visible, headername } = this.state;
        // console.log('okay', headername)
        if (this.state.loader == true) {
            setTimeout(function () {
                this.setState({ loader: false });
            }.bind(this), 2000);
        }
        return (
            <header id="mainHeader" className={` ${(headername == "/index2") ? "site-header  bg-dark navbar-dark" : "site-header  navbar-dark"}`}>
                <div id="header-wrap" className={` ${(headername == "/index2") ? "" : "position-absolute w-100 z-index-1"} ${(visible) ? "fixed-header " : ""}`}>
                    <div className="container">
                        <div className="row">
                            {/*menu start*/}
                            <div className="col d-flex align-items-center justify-content-between">
                                {/* 
                                <Link className="navbar-brand logo text-white  h2 mb-0" to="/">
                                    Boots<span className="font-weight-bold">Land.</span>
                                </Link> 
                                */}
                                <LinkScroll className="navbar-brand logo text-white h2 mb-0 pointer" style={{ maxWidth: "210px" }} to="home" duration={computeDuration} offset={-250} smooth={true}>
                                    <img src={logo} alt="logo not found" width="100%" />
                                </LinkScroll>
                                <Navbar className="navbar-expand-lg navbar-light ml-auto">
                                    <NavbarToggler onClick={this.toggle} />
                                    <Collapse isOpen={this.state.isOpen} className=" navbar-collapse" navbar>
                                        <Nav className="ml-auto" navbar>
                                            {/* {navLinks.map((navLink, index) => (

                                                (navLink.type && navLink.type === 'subMenu') ?
                                                    <UncontrolledDropdown nav inNavbar key={index}>
                                                        <DropdownToggle nav caret >
                                                            {navLink.menu_title}
                                                        </DropdownToggle>
                                                        <DropdownMenu id={`submenu_${index}`} className="childsubmenu">
                                                            {navLink.child_routes && navLink.child_routes.map((subNavLink, index) => (
                                                                (subNavLink.type && subNavLink.type === 'childsubMenu') ?
                                                                    <UncontrolledDropdown nav inNavbar className="dropdown-submenu" key={index}>
                                                                        <DropdownToggle nav caret className="dropdown-item" >
                                                                            {subNavLink.menu_title}
                                                                        </DropdownToggle>
                                                                        <DropdownMenu id={`childsubmenu_${index}`}>
                                                                            {subNavLink.child_routes && subNavLink.child_routes.map((ChildsubNavLink, i) =>
                                                                                <DropdownItem key={i} tag={Link} to={ChildsubNavLink.path} onClick={this.handleClick.bind(this)} >{ChildsubNavLink.menu_title}
                                                                                </DropdownItem>
                                                                            )}
                                                                        </DropdownMenu>
                                                                    </UncontrolledDropdown>
                                                                    :
                                                                    <DropdownItem key={index} tag={Link} to={subNavLink.path}>{subNavLink.menu_title}
                                                                    </DropdownItem>
                                                            ))}
                                                        </DropdownMenu>
                                                    </UncontrolledDropdown>
                                                    :
                                                    <NavItem>
                                                        <NavLink href={navLink.path}> {navLink.menu_title}</NavLink>
                                                    </NavItem>
                                            ))} */}

                                            <NavItem>
                                                <LinkScroll onClick={this.state.isOpen ? this.toggle : null} className="nav-link pointer" to="ourProgarms" duration={computeDuration} offset={-250} smooth={true}>
                                                    <FormattedMessage id="topbar.our_programs" />
                                                </LinkScroll>
                                            </NavItem>
                                            <NavItem>
                                                <LinkScroll onClick={this.state.isOpen ? this.toggle : null} className="nav-link pointer" to="contact" duration={computeDuration} offset={-60} smooth={true}>
                                                    <FormattedMessage id="topbar.contact" />
                                                </LinkScroll>
                                            </NavItem>

                                            {/* languages start */}
                                            <UncontrolledDropdown nav inNavbar>
                                                <DropdownToggle nav caret >
                                                    <GlobeIcon />
                                                    {(/^\/fr/).test(window.location.pathname) ? ' Français' : ' English'}
                                                </DropdownToggle>
                                                <DropdownMenu id={`submenu_languages`} className="childsubmenu">
                                                    <IntlContextConsumer>
                                                        {({ languages, language: currentLocale }) =>
                                                            languages.map(language => (
                                                                <DropdownItem
                                                                    key={language}
                                                                    onClick={() => changeLocale(language)}
                                                                    style={{
                                                                        pointerEvents: currentLocale === language ? `none` : `initial`,
                                                                        opacity: currentLocale === language ? `0.6` : `1`,
                                                                    }}
                                                                >
                                                                    <span className={`flag-icon flag-icon-${languageName[language].code} mx-2`}></span>
                                                                    {languageName[language][currentLocale]}
                                                                </DropdownItem>
                                                            ))
                                                        }
                                                    </IntlContextConsumer>
                                                </DropdownMenu>
                                            </UncontrolledDropdown>
                                            {/* languages end */}
                                        </Nav>
                                    </Collapse>
                                </Navbar>
                                <LinkScroll to="form" duration={computeDuration} offset={-84} smooth={true}>
                                    <button className="btn btn-light ml-8 d-none d-lg-block">
                                        <FormattedMessage id="topbar.register_btn" />
                                    </button>
                                </LinkScroll>
                            </div>
                            {/*menu end*/}
                        </div>
                    </div>
                </div>
            </header>

        );
    }
}

export default Header3;