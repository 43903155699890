import React, { Component } from 'react';
import { Link } from 'gatsby';
import { Col, Container, Row } from 'reactstrap';
import toast from 'react-hot-toast';
import { FormattedHTMLMessage ,useIntl } from "gatsby-plugin-intl";

import { computeWaveShapeHeight } from '../../utils/helpers'


const links = [
    {
        name: "facebook",
        label: {
            en:  "Facebook page",
            fr: "page Facebook"
        },
        available: false,
        show: true
    },
    {
        name: "instagram",
        label: {
            en:  "Instagram account",
            fr: "compte Instagram"
        },
        available: false,
        show: true
    },
    {
        name: "twitter",
        label: {
            en:  "Twitter account",
            fr: "compte Twitter"
        },
        available: false,
        show: false
    },
    {
        name: "linkedin",
        label: {
            en:  "Linkedin account",
            fr: "compte Linkedin"
        },
        available: false,
        show: true
    },
]

const BoxArrow = () => {
    return (
        <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 16 16" height="0.7em" width="0.7em" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" d="M14.5 3A1.5 1.5 0 0013 1.5H3A1.5 1.5 0 001.5 3v5a.5.5 0 001 0V3a.5.5 0 01.5-.5h10a.5.5 0 01.5.5v10a.5.5 0 01-.5.5H9a.5.5 0 000 1h4a1.5 1.5 0 001.5-1.5V3z" clipRule="evenodd"></path><path fillRule="evenodd" d="M4.5 6a.5.5 0 01.5-.5h5a.5.5 0 01.5.5v5a.5.5 0 01-1 0V6.5H5a.5.5 0 01-.5-.5z" clipRule="evenodd"></path><path fillRule="evenodd" d="M10.354 5.646a.5.5 0 010 .708l-8 8a.5.5 0 01-.708-.708l8-8a.5.5 0 01.708 0z" clipRule="evenodd"></path>
        </svg>
    )
}

const Footer = ({width, breakPoints}) => {
    const {w576AndUp, w768AndUp} = breakPoints;
    const intl = useIntl();
    const showToast = (label) => {
        toast(
            t => (
                <div className="toastForm">
                  <b><FormattedHTMLMessage id="social_networks_availibility" values={{label: label[intl.locale]}} /></b>
                  <div className="x" onClick={() => toast.dismiss(t.id)}></div>
                </div>
            ), 
            {
                icon: '⛔',
            });
    }
    return (
        <footer className="pt-11 pb-5 bg-primary position-relative" data-bg-img="assets/images/bg/03.png">
            <div className="shape-1" style={{ height: computeWaveShapeHeight(width), overflow: 'hidden' }}>
                <svg viewBox="0 0 500 150" preserveAspectRatio="none" style={{ height: '100%', width: '100%' }}>
                    <path d="M0.00,49.98 C150.00,150.00 271.49,-50.00 500.00,49.98 L500.00,0.00 L0.00,0.00 Z" style={{ stroke: 'none', fill: 'rgb(255, 255, 255)' }} />
                </svg>
            </div>
            <Container className=" mt-0">
                <Row>
                    <div className="col-12">
                        <Row className="mt-3" >
                            <Col className="col-12 text-right">
                                <a className="footer-logo text-white h2 mb-0" href="https://labs.devmastery.tech/login" target="_blank">
                                    DevMastery<span className="font-weight-bold">Labs</span> Platform <BoxArrow />
                                </a>
                            </Col>
                            <Col className="col-12 col-sm-6 mt-3 mt-sm-0">
                                <ul className="list-inline mb-0">
                                    {
                                        links.filter(link => link.show).map((link, i) => (
                                            <li key={i} onClick={!link.available ? () => showToast(link.label) : null} className="list-inline-item">
                                                <a className="text-light ic-2x" style={{cursor: 'pointer'}}><i className={`la la-${link.name}`} /></a>
                                            </li>
                                        ))
                                    }
                                </ul>
                            </Col>
                        </Row>
                    </div>
                </Row>
                <Row className="text-white text-center mt-0">
                    <Col>
                        <hr className={`mb-${w576AndUp ? '8' : '3'}`} />Copyright © {new Date().getFullYear()} DevMastery | All Rights Reserved
                    </Col>
                </Row>
            </Container>
        </footer>
    );
}

export default Footer;