import React from 'react';

const SecondEditorContent = () => {
    return (
        <>
            <div className="containerDiv" style={{paddingLeft: "0px"}}>
                <span className="coloredSpans" style={{backgroundColor: "#78c078", animation: "coloredSpan1 100ms 1400ms forwards"}}></span>
                <span className="coloredSpans" style={{backgroundColor: "#5e5efb", animation: "coloredSpan2 100ms 1500ms forwards"}}></span>
                <span className="coloredSpans" style={{backgroundColor: "#48aca2", animation: "coloredSpan3 100ms 1600ms forwards"}}></span>
            </div>
            <div className="containerDiv" style={{paddingLeft: "20px"}}>
                <span className="coloredSpans" style={{backgroundColor: "#48aca2", animation: "coloredSpan4 100ms 1700ms forwards"}}></span>
                <span className="coloredSpans" style={{backgroundColor: "#5e5efb", animation: "coloredSpan5 100ms 1800ms forwards"}}></span>
                <span className="coloredSpans" style={{backgroundColor: "#78c078", animation: "coloredSpan6 100ms 1900ms forwards"}}></span>
                <span className="coloredSpans" style={{backgroundColor: "#48aca2", animation: "coloredSpan7 100ms 2000ms forwards"}}></span>
            </div>
            <div className="containerDiv" style={{paddingLeft: "40px"}}>
                <span className="coloredSpans" style={{backgroundColor: "#5a395a", animation: "coloredSpan8 100ms 2100ms forwards"}}></span>
                <span className="coloredSpans" style={{backgroundColor: "#48aca2", animation: "coloredSpan9 100ms 2200ms forwards"}}></span>
                <span className="coloredSpans" style={{backgroundColor: "#78c078", animation: "coloredSpan10 100ms 2300ms forwards"}}></span>
                <span className="coloredSpans" style={{backgroundColor: "#78c078", animation: "coloredSpan11 100ms 2400ms forwards"}}></span>
            </div>
            <div className="containerDiv" style={{paddingLeft: "40px"}}>
                <span className="coloredSpans" style={{backgroundColor: "#be8a28", animation: "coloredSpan12 100ms 2500ms forwards"}}></span>
                <span className="coloredSpans" style={{backgroundColor: "#5a395a", animation: "coloredSpan13 100ms 2600ms forwards"}}></span>
                <span className="coloredSpans" style={{backgroundColor: "#be8a28", animation: "coloredSpan14 100ms 2700ms forwards"}}></span>
                <span className="coloredSpans" style={{backgroundColor: "#48aca2", animation: "coloredSpan15 100ms 2800ms forwards"}}></span>
            </div>
            <div className="containerDiv" style={{paddingLeft: "20px"}}>
                <span className="coloredSpans" style={{backgroundColor: "#5e5efb", animation: "coloredSpan16 100ms 2900ms forwards"}}></span>
                <span className="coloredSpans" style={{backgroundColor: "#5a395a", animation: "coloredSpan17 100ms 3000ms forwards"}}></span>
            </div>
            <br />
            <div className="containerDiv" style={{paddingLeft: "0px"}}>
                <span className="coloredSpans" style={{backgroundColor: "#be8a28", animation: "coloredSpan18 100ms 3100ms forwards"}}></span>
                <span className="coloredSpans" style={{backgroundColor: "#5a395a", animation: "coloredSpan19 100ms 3200ms forwards"}}></span>
                <span className="coloredSpans" style={{backgroundColor: "#48aca2", animation: "coloredSpan20 100ms 3300ms forwards"}}></span>
            </div>
            <div className="containerDiv" style={{paddingLeft: "20px"}}>
                <span className="coloredSpans" style={{backgroundColor: "#5a395a", animation: "coloredSpan21 100ms 3400ms forwards"}}></span>
                <span className="coloredSpans" style={{backgroundColor: "#5e5efb", animation: "coloredSpan22 100ms 3500ms forwards"}}></span>
                <span className="coloredSpans" style={{backgroundColor: "#48aca2", animation: "coloredSpan23 100ms 3600ms forwards"}}></span>
                <span className="coloredSpans" style={{backgroundColor: "#78c078", animation: "coloredSpan24 100ms 3700ms forwards"}}></span>
            </div>
            <div className="containerDiv" style={{paddingLeft: "40px"}}>
                <span className="coloredSpans" style={{backgroundColor: "#48aca2", animation: "coloredSpan25 100ms 3800ms forwards"}}></span>
                <span className="coloredSpans" style={{backgroundColor: "#5e5efb", animation: "coloredSpan26 100ms 3900ms forwards"}}></span>
                <span className="coloredSpans" style={{backgroundColor: "#78c078", animation: "coloredSpan27 100ms 4000ms forwards"}}></span>
                <span className="coloredSpans" style={{backgroundColor: "#be8a28", animation: "coloredSpan28 100ms 4100ms forwards"}}></span>
            </div>
            <div className="containerDiv" style={{paddingLeft: "40px"}}>
                <span className="coloredSpans" style={{backgroundColor: "#be8a28", animation: "coloredSpan29 100ms 4200ms forwards"}}></span>
                <span className="coloredSpans" style={{backgroundColor: "#48aca2", animation: "coloredSpan30 100ms 4300ms forwards"}}></span>
                <span className="coloredSpans" style={{backgroundColor: "#5a395a", animation: "coloredSpan31 100ms 4400ms forwards"}}></span>
                <span className="coloredSpans" style={{backgroundColor: "#48aca2", animation: "coloredSpan32 100ms 4500ms forwards"}}></span>
            </div>
            <div className="containerDiv" style={{paddingLeft: "20px"}}>
                <span className="coloredSpans" style={{backgroundColor: "#78c078", animation: "coloredSpan33 100ms 4600ms forwards"}}></span>
                <span className="coloredSpans" style={{backgroundColor: "#5a395a", animation: "coloredSpan34 100ms 4700ms forwards"}}></span>
            </div>
        </>
    )
}

export default SecondEditorContent;