import React from 'react';
import LoaderImg from './LoaderImg'

import './Loader.css';

const Loader = () => {
  return (
    <div className='loaderWrapper'>
      <div className='bigLoader'>
        <span>
          {/* <img alt='DevMastery' src='img/icon/blacklogoicon.png' width='160' /> */}
          <LoaderImg />
        </span>
      </div>
    </div>
  );
};

export default Loader;
