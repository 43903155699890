import React from 'react';

const TerminalIcon = () => {
    return (
<svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 16 16" height="100%" width="70%" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" d="M0 3a2 2 0 012-2h12a2 2 0 012 2v10a2 2 0 01-2 2H2a2 2 0 01-2-2V3zm9.5 5.5h-3a.5.5 0 000 1h3a.5.5 0 000-1zm-6.354-.354L4.793 6.5 3.146 4.854a.5.5 0 11.708-.708l2 2a.5.5 0 010 .708l-2 2a.5.5 0 01-.708-.708z" clipRule="evenodd"></path>
</svg>
    )
}

export default TerminalIcon;