import React, { useEffect, useState } from "react";
import Spinner from '../loader/loader';

const Output = () => {
    return (
        <div>
            {
                ['250ms', '750ms', '1250ms', '1750ms'].map((el, i) => Math.random() >= 0.5
                    ? (
                        <div key={i} className="divs" style={{ backgroundColor: "#15314b", margin: "0 0 15px", borderRadius: "4px", boxShadow: "0 2px 4px rgba(50,50,93,.1)", padding: "7px 10px", display: "flex", alignItems: "center", animation: `div 500ms ${el} forwards` }}>
                            <svg viewBox="0 0 232 232" fill="none" xmlns="http://www.w3.org/2000/svg" style={{ width: "25px", color: "#c54242", margin: "0 10px 0 0" }}><circle className="circle" cx="116" cy="116" r="108.5" stroke="currentColor" strokeWidth="15"></circle><path className="line1" stroke="currentColor" strokeWidth="15" strokeLinecap="round" d="M67.32 162.789l95.236-95.681"></path><path className="line2" stroke="currentColor" strokeWidth="15" strokeLinecap="round" d="M164.9 162.562L69.219 67.325"></path>
                            </svg>
                            <div style={{ width: "60%", height: "13px", borderRadius: "4px", backgroundColor: "#c54242" }}></div>
                        </div>
                    )
                    : (
                        <div key={i} className="divs" style={{ backgroundColor: "#15314b", margin: "0 0 15px", borderRadius: "4px", boxShadow: "0 2px 4px rgba(50,50,93,.1)", padding: "7px 10px", display: "flex", alignItems: "center", animation: `div 500ms ${el} forwards` }}>
                            <svg viewBox="0 0 232 232" fill="none" xmlns="http://www.w3.org/2000/svg" style={{ width: "25px", color: "#3f8854", margin: "0 10px 0 0" }}><circle className="circle" cx="116" cy="116" r="108.5" stroke="currentColor" strokeWidth="15" strokeLinejoin="round"></circle><path className="line1" stroke="currentColor" strokeWidth="15" strokeLinecap="round" strokeLinejoin="round" d="M65.606 114.888l37.282 36.506"></path><path className="line2" stroke="currentColor" strokeWidth="15" strokeLinecap="round" strokeLinejoin="round" d="M177.741 80.603l-73.138 69.656"></path>
                            </svg>
                            <div style={{ width: "60%", height: "13px", borderRadius: "4px", backgroundColor: "#3f8854" }}></div>
                        </div>
                    ))
            }


        </div>
    )
}

export default function FourthEditorContent({ showSpinnerOnLoad, setLoad, ShowSpinnerAgain, setShowSpinnerAgain, ShowOutput, setShowOutput }) {




    useEffect(() => {
        setTimeout(() => {
            setLoad(true);
            setTimeout(() => {
                setLoad(false);
                setShowOutput(true);
            }, 2000);
        }, 5000);
    }, [])


    return (
        <div className="App">
            {
                showSpinnerOnLoad
                    ? <Spinner />
                    : (ShowSpinnerAgain
                        ? <Spinner />
                        : (ShowOutput
                            ? <Output />
                            : ''))
            }
        </div>
    );
}
