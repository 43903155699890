import React, { useEffect } from 'react';
import Spinner from '../editors/loader/loader';

import "./editorPreview.css"

const EditorTemplate = ({ editor, showSpinnerOnLoad, setLoad, ShowSpinnerAgain, setShowSpinnerAgain, ShowOutput, setShowOutput, click }) => {

    const afterClick = (ShowOutput && !ShowSpinnerAgain && click)

    return (
        <>
            <div style={{ opacity: 0, animation: `headerAndavatar 800ms ${afterClick ? '100ms' : '3000ms'} forwards` }} id="header"></div>
            <div style={{ opacity: 0, animation: `headerAndavatar 800ms ${afterClick ? '100ms' : '3000ms'} forwards` }} id="avatar"></div>
            <div id="posts" style={{ transform: !afterClick && "scale(0.6)", opacity: 0, animation: `${afterClick ? 'afterClick' : 'posts'} 800ms ${afterClick ? '100ms' : '4700ms'} forwards` }}>
                <div id="infos">
                    <div id="post">
                        <div id="sm-avatar"></div>
                        <div id="details">
                            <div id="name"></div>
                            <div id="subname"></div>
                        </div>
                    </div>
                    <div id="content">
                        <div className="lines line1"></div>
                        <div className="lines line2"></div>
                        <div className="lines line3"></div>
                    </div>
                </div>
                <div id="infos">
                    <div id="post">
                        <div id="sm-avatar"></div>
                        <div id="details">
                            <div id="name"></div>
                            <div id="subname"></div>
                        </div>
                    </div>
                    <div id="content">
                        <div className="lines line1"></div>
                        <div className="lines line2"></div>
                        <div className="lines line3"></div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default EditorTemplate;