import React from 'react';

const FirstEditorContent = () => {
    return (
        <>
            <h4 style={{color: "#fff", margin: "0 0 10px", fontSize: "14px", fontWeight: "600px", animation: "courseAndInstructions 650ms 750ms forwards", opacity: 0}}>Course</h4>
            <div className="greyFakeDiv" style={{animation: "greyFakeDiv1 350ms 1000ms forwards"}}></div>
            <div className="greyFakeDiv" style={{animation: "greyFakeDiv2 350ms 1350ms forwards"}}></div>
            <div className="greyFakeDiv" style={{animation: "greyFakeDiv3 350ms 1700ms forwards"}}></div>
            <div className="greyFakeDiv" style={{animation: "greyFakeDiv4 350ms 2050ms forwards"}}></div>
            <div className="greyFakeDiv" style={{animation: "greyFakeDiv5 350ms 2400ms forwards"}}></div>
            <br />
            <h4 style={{color: "#fff", margin: "0 0 10px", fontSize: "14px", fontWeight: "600px", animation: "courseAndInstructions 650ms 2650ms forwards", opacity: 0}}>Instructions</h4>
            <div className="greyFakeDiv" style={{animation: "greyFakeDiv6 350ms 3100ms forwards"}}></div>
            <div className="greyFakeDiv" style={{animation: "greyFakeDiv7 350ms 3450ms forwards"}}></div>
            <div className="greyFakeDiv" style={{animation: "greyFakeDiv8 350ms 3800ms forwards"}}></div>
            <div className="greyFakeDiv" style={{animation: "greyFakeDiv9 350ms 4150ms forwards"}}></div>
        </>
    )
}

export default FirstEditorContent;