import React, { useState } from "react"

import EditorTemplate from "./editorTemplate"
import FirstEditorContent from "./editorsContent/firstEditorContent"
import SecondEditorContent from "./editorsContent/secondEditorContent"
import FourthEditorContent from "./editorsContent/fourthEditorContent"
import ThirdEditorContent from "./editorsContent/thirdEditorContent"
import Preview from './editorPreview'

const Editor = () => {
  const [showSpinnerOnLoad, setLoad] = useState(false);
  const [ShowSpinnerAgain, setShowSpinnerAgain] = useState(false);
  const [ShowOutput, setShowOutput] = useState(false);
  const [click, setClick] = useState(false);


  const handleClick = () => {
    setShowSpinnerAgain(true);
    if (!click) {
      setClick(true);
    }
    setTimeout(() => {
      setShowSpinnerAgain(false);
      setShowOutput(true);
    }, 2000);
  }

  return (
    <div className="editorContainer inhHtmlBody">
      <div className="firstGridColumn">
        <EditorTemplate title="Lab" editor="editor1"><FirstEditorContent /></EditorTemplate>
        <EditorTemplate title="Tests Output" editor="editor4" setShowSpinnerAgain={setShowSpinnerAgain} handleClick={handleClick} exactHeight="200px">
          <FourthEditorContent
            showSpinnerOnLoad={showSpinnerOnLoad}
            setLoad={setLoad}
            ShowSpinnerAgain={ShowSpinnerAgain}
            setShowSpinnerAgain={setShowSpinnerAgain}
            ShowOutput={ShowOutput}
            setShowOutput={setShowOutput}
          />
        </EditorTemplate>
      </div>
      <div className="secondGridColumn">
        <EditorTemplate title="Code Editor" editor="editor2" hasBtn="Run" setShowSpinnerAgain={setShowSpinnerAgain} handleClick={handleClick}><SecondEditorContent /></EditorTemplate>
        <EditorTemplate title="Console" editor="editor3" setShowSpinnerAgain={setShowSpinnerAgain} exactHeight="150px"><ThirdEditorContent ShowSpinnerAgain={ShowSpinnerAgain} ShowOutput={ShowOutput} click={click} /></EditorTemplate>
      </div>
      <div className="thirdGridColumn">
      <EditorTemplate title="Preview" editor="editor5" ShowSpinnerAgain={ShowSpinnerAgain} ShowOutput={ShowOutput} click={click} setShowSpinnerAgain={setShowSpinnerAgain} preview><Preview ShowSpinnerAgain={ShowSpinnerAgain} ShowOutput={ShowOutput} click={click} /></EditorTemplate>
      </div>

    </div>
  )
}

export default Editor
