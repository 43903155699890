import React from 'react';
import { FormGroup, Label, Input, FormFeedback, FormText } from 'reactstrap';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from "yup";
import toast, { Toaster } from 'react-hot-toast';
import { FormattedHTMLMessage, FormattedMessage, useIntl } from "gatsby-plugin-intl";

import SimpleInput from './SimpleInput';
import PhoneInput from './PhoneInput';
import SelectInput from './SelectInput';


const initialValues = {
    // properties must correspond to the name of each field in the form
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "216",
    type: "",
    format: "",
    program: ""
};

const ProfessionalForm = () => {
    const intl = useIntl();

    const requiredMsg = intl.formatMessage({ id: "validation.required" });
    const invalidEmailAdressMsg = intl.formatMessage({ id: "validation.invalid.email_address" });
    const invalidPhoneNumberMsg = intl.formatMessage({ id: "validation.invalid.phone_number" });

    // Validation schema object : that's pretty much what yup do : Yup object schema
    const validationSchema = Yup.object({
        // we need to pass in an object which contains the rules for every form fields
        firstName: Yup.string().required(requiredMsg),
        lastName: Yup.string().required(requiredMsg),
        email: Yup.string().email(invalidEmailAdressMsg).required(requiredMsg),
        type: Yup.string().required(requiredMsg),
        format: Yup.string().required(requiredMsg),
        // program: Yup.string().required(requiredMsg),
    });

    const validatePhoneNumber = value => {
        let error;
        if (value.startsWith('216')) { // tunisian phone number
            if (value.length === 3) return error = requiredMsg;
            if (value.length !== 11) error = invalidPhoneNumberMsg;
            if (value.length === 11) {
                if (value.startsWith('0', 3) || value.startsWith('1', 3) || value.startsWith('8', 3)) error = invalidPhoneNumberMsg;
            }
    
        }
        return error
    }

    const handleSubmit = async (values, onSubmitProps) => {
        const data = {
            ...values,
            phoneNumber: `+${values.phoneNumber}`, 
            program: values.program ? values.program : null
        }
        try {
            const rawResponse = await fetch(`${process.env.API_URL}/leads`, {
                method: 'POST',
                headers: {
                  'Accept': 'application/json',
                  'Content-Type': 'application/json'
                },
                body: JSON.stringify(data)
            });
              const {type, message, errors} = await rawResponse.json();
              onSubmitProps.setSubmitting(false);
              const firstName = values.firstName;
              if (type === 'error') return toast.error(
                t => (
                <div className="toastForm">
                    <ul className="m-0">
                        {Object.values(errors).map((err, i) => <li key={i}>{err}</li>)}
                    </ul>
                    <div className="x" onClick={() => toast.dismiss(t.id)}></div>
                </div>
                ),
                {
                duration: 1000 * 20
                }
              );
              onSubmitProps.resetForm();
              toast.success(
                t => (
                  <div className="toastForm">
                    <b><FormattedMessage id="form.success.title" values={{firstName}} /></b><br/>
                    <FormattedMessage id="form.success.description" />
                    <div className="x" onClick={() => toast.dismiss(t.id)}></div>
                  </div>
                ),
                {
                  duration: 1000 * 60 * 60 
                }
              );
        } catch (error) {
            // console.log(JSON.stringify(error.response.data, null, 2))
            // const { message, type } = error.response.data;
            // console.log(error);
            onSubmitProps.setSubmitting(false);
            toast.error(
                t => (
                  <div className="toastForm">
                    <b><FormattedMessage id="form.fail.title" /></b><br/>
                    <div className="x" onClick={() => toast.dismiss(t.id)}></div>
                  </div>
                ),
                {
                  duration: 1000 * 20
                }
            );
        }
    };

    return (
        <div id="form" className="shadow rounded p-5">
            <Toaster 
                position="bottom-center" 
                reverseOrder={true} 
                toastOptions={{
                    style: {
                        boxShadow: 'rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px'
                    }
                }}
            />
            <img className="img-fluid mb-8" src={require(`../../assets/images/about/04.png`)} alt="Image" />
            <Formik
                initialValues={initialValues}
                onSubmit={handleSubmit}
                validationSchema={validationSchema}
            >
                {
                    formik => {
                        return (
                            <Form className="row" id="contact-form" noValidate>
                                <div className="messages" />
                                <FormGroup className="col-sm-6 mb-2">
                                    <Field name="firstName">
                                        {
                                            props => (
                                                <SimpleInput
                                                    propsFromFormik={props}
                                                    label={intl.formatMessage({ id: "form.fields.first_name" })}
                                                    refName="firstName"
                                                    type="text"
                                                />
                                            )
                                        }
                                    </Field>
                                </FormGroup>
                                <FormGroup className="col-sm-6 mb-2">
                                    <Field name="lastName">
                                        {
                                            props => (
                                                <SimpleInput
                                                    propsFromFormik={props}
                                                    label={intl.formatMessage({ id: "form.fields.last_name" })}
                                                    refName="lastName"
                                                    type="text"
                                                />
                                            )
                                        }
                                    </Field>
                                </FormGroup>
                                <FormGroup className="col-12 mb-2">
                                    <Field name="email">
                                        {
                                            props => (
                                                <SimpleInput
                                                    propsFromFormik={props}
                                                    label={intl.formatMessage({ id: "form.fields.email" })}
                                                    refName="email"
                                                    type="email"
                                                />
                                            )
                                        }
                                    </Field>
                                </FormGroup>
                                <FormGroup className="col-12 mb-2">
                                    <Field name='phoneNumber' validate={validatePhoneNumber}>
                                        {
                                            props => (
                                                <PhoneInput
                                                    propsFromFormik={props}
                                                    label={intl.formatMessage({ id: "form.fields.phone_number" })}
                                                    refName="phoneNumber"
                                                    type="text"
                                                />
                                            )
                                        }
                                    </Field>
                                </FormGroup>
                                <FormGroup className="col-12 mb-2">
                                    <Field name='type'>
                                        {
                                            props => (
                                                <SelectInput
                                                    propsFromFormik={props}
                                                    label={intl.formatMessage({ id: "form.fields.type.name" })}
                                                    refName="type"
                                                    options={[
                                                        { name: intl.formatMessage({ id: "form.fields.type.online" }), dashedName: "online" },
                                                        { name: intl.formatMessage({ id: "form.fields.type.at_school.sfax" }), dashedName: "school-of-sfax" },
                                                    ]}
                                                />
                                            )
                                        }
                                    </Field>
                                </FormGroup>
                                <FormGroup className="col-12 mb-2">
                                    <Field name='format'>
                                        {
                                            props => (
                                                <SelectInput
                                                    propsFromFormik={props}
                                                    label={intl.formatMessage({ id: "form.fields.format.name" })}
                                                    refName="format"
                                                    options={[
                                                        { name: intl.formatMessage({ id: "form.fields.format.full_time" }), dashedName: "full-time" },
                                                        { name: intl.formatMessage({ id: "form.fields.format.part_time" }), dashedName: "part-time" },
                                                    ]}
                                                />
                                            )
                                        }
                                    </Field>
                                </FormGroup>
                                <FormGroup className="col-12 mb-2">
                                    <Field name='program'>
                                        {
                                            props => (
                                                <SelectInput
                                                    propsFromFormik={props}
                                                    label={intl.formatMessage({ id: "form.fields.program" })}
                                                    refName="program"
                                                    options={[
                                                        { name: "HTML & CSS", dashedName: "html-and-css" },
                                                        { name: "Javascript, Terminal & Git", dashedName: "javascript-terminal-and-git" },
                                                        { name: "Front-end with React", dashedName: "front-end-with-react" },
                                                        { name: "Back-end with NodeJS, Express & MongoDB", dashedName: "back-end-with-nodejs-express-and-mongodb" },
                                                        { name: "FullStack development", dashedName: "full-stack" }
                                                    ]}
                                                />
                                            )
                                        }
                                    </Field>
                                </FormGroup>
                                <div className="col-12 mt-5">
                                    <button
                                        className="btn btn-primary w-100 d-flex justify-content-center align-items-center"
                                        type='submit'
                                        disabled={formik.isSubmitting}
                                    >
                                        {formik.isSubmitting && <span className="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true"></span>}
                                        {formik.isSubmitting ? intl.formatMessage({ id: "form.register_btn.loading" }) : intl.formatMessage({ id: "form.register_btn.submit" })}
                                    </button>
                                </div>
                            </Form>
                        )
                    }
                }
            </Formik>
        </div>
    );
}

export default ProfessionalForm;