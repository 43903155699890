import React, {useEffect} from 'react';
import Spinner from './loader/loader'

import "./style.css"

const EditorTemplate = ({ children, title, editor, ShowSpinnerAgain, ShowOutput, click, hasBtn, exactHeight, handleClick, preview }) => {

    const shadow = (editor === "editor3" || editor === "editor4") 
                            ? "0 50px 80px rgb(0 0 0 / 40%)" 
                            : editor === "editor5" 
                                ? "0 50px 80px rgb(0 0 0 / 40%), 50px 0px 80px rgb(0 0 0 / 25%)" 
                                : "";

    useEffect(() => {
        setTimeout(() => {
            if (document.getElementById('yz')) {
                document.getElementById('yz').classList.add("editor-content");
                document.getElementById('yz').style.overflowY = "scroll";
                document.getElementById('yz').style.padding = "15px";
            }
        }, 4800);

    }, [])

    if (!preview) return (

        <div className={editor} style={{backgroundColor: "rgb(55,65,75)", fontFamily: "monospace", fontSize: "15px", borderRadius: "5px", border: "2px solid #15314b", boxShadow: shadow, transform: "scale(0.6)", opacity: 0 }}>
            <div className="header" style={{ boxSizing: "border-box", borderBottom: "2px solid #15314b", padding: "5px", backgroundColor: "#15314b", boxShadow: "0 0 5px 0 rgba(0,0,0,.4)", display: "flex", justifyContent: "space-between" }}>
                <span className="title" style={{ margin: "0 0 0 10px", color: "#fff" }}>{title}</span>
                {hasBtn && <button onClick={() => handleClick()} style={{ margin: "-5px", padding: "0 15px", borderColor: "azure" }}>{hasBtn}</button>}

            </div>
            <div className="editor-content" style={{ padding: "15px", backgroundColor: "#02203C", overflowY: "auto", height: exactHeight && exactHeight }}>
                {children}
            </div>
        </div>
    ); else return (

        <div className={editor} style={{backgroundColor: "rgb(55,65,75)", fontFamily: "monospace", fontSize: "15px", borderRadius: "5px", border: "2px solid #15314b", boxShadow: shadow, transform: "scale(0.6)", opacity: 0 }}>
            <div className="header" style={{ boxSizing: "border-box", borderBottom: "2px solid #15314b", padding: "5px", backgroundColor: "#15314b", boxShadow: "0 0 5px 0 rgba(0,0,0,.4)", display: "flex", justifyContent: "space-between" }}>
                <span className="title" style={{ margin: "0 0 0 10px", color: "#fff" }}>{title}</span>
                {hasBtn && <button onClick={() => handleClick()} style={{ margin: "-5px", padding: "0 15px", borderColor: "azure" }}>{hasBtn}</button>}

            </div>
            {
                ShowSpinnerAgain
                ? <Spinner />
                : ((ShowOutput && !ShowSpinnerAgain && click)
                    ? (
                        <div className="editor-content" style={{padding: "15px", backgroundColor: "#02203C", overflowY: "scroll", height: exactHeight && exactHeight }}>
                        {children}
                        </div>
                    )
                    : (
                    <div id="yz" style={{padding: "15px 21.5px 15px 15px", backgroundColor: "#02203C", overflowY: "hidden", height: exactHeight && exactHeight }}>
                        {children}
                    </div>
                    )
                )
            }

        </div>
    )
}

export default EditorTemplate;